import {
  JobSummaryFragment,
  JobType,
  useGetJobsQuery,
} from '@/generated/graphql';
import {
  getConversationId,
  getCurrentMessage,
  getItemParticipants,
} from '@/utils/microsoft-graph';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useInterval } from '@shared/hooks/useInterval';
import { filterJobsToDisplay } from '@shared/plugin/utils/optimisticJobs';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type OutlookAdaptorState = {
  jobs: JobSummaryFragment[];
  jobsLoading: boolean;
  refetchThreadContext: () => Promise<void>;
  jobTypes: Record<string, JobType>;
  storeJobType: (jobType: JobType, jobId?: string) => void;
  item?: Office.MessageRead;
  itemBody?: string;
  conversationId?: string;
  isSharedInbox: boolean;
  sharedInboxOwner?: string;
  sharedInboxCandidates: string[];
  selectSharedInboxCandidate: (email?: string) => void;
};

const POLL_INTERVAL_FREQUENT_SECONDS = 3 * 1000;
const POLL_INTERVAL_INFREQUENT_SECONDS = 30 * 1000;

/**
 * Provides the jobs related to the currently focused email thread.
 * Additionally, polls on an interval with conditional frequency
 * depending on the number of processing jobs.
 */
export const useOutlookAdaptor = (): OutlookAdaptorState => {
  const [conversationId, setConversationId] = useState<string>();
  const [item, setItem] = useState<Office.MessageRead>();
  const [itemBody, setItemBody] = useState<string>();
  const [jobTypes, setJobTypes] = useState<Record<string, JobType>>({});
  const { defaultOrgId } = useMeContext();
  const [sharedInboxOwner, setSharedInboxOwner] = useLocalStorage<
    string | undefined
  >('shared-inbox-owner', undefined);
  const [isSharedInbox, setIsSharedInbox] = useState(Boolean(sharedInboxOwner));
  const [sharedInboxCandidates, setSharedInboxCandidates] = useState<string[]>(
    []
  );

  const { data, startPolling, stopPolling, loading } = useGetJobsQuery({
    variables: {
      input: {
        organizationIds: defaultOrgId ? [defaultOrgId] : [],
        outlookFilter: {
          conversationId,
          sharedInbox: sharedInboxOwner,
        },
      },
    },
    skip: !conversationId,
    pollInterval: POLL_INTERVAL_INFREQUENT_SECONDS,
  });

  const refetchThreadContext = async () => {
    const conversationId = getConversationId();
    setConversationId(conversationId);

    const item = getCurrentMessage();
    if (item) {
      item.body.getAsync(Office.CoercionType.Text, (res) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          setItemBody(res.value);
          setItem(item);
        }
      });
    } else {
      setItem(undefined);
      setItemBody('');
    }
  };

  useInterval(async () => {
    await refetchThreadContext();
  }, 1000 * 60);

  useEffect(() => {
    Office.context.mailbox.addHandlerAsync(
      Office.EventType.ItemChanged,
      refetchThreadContext
    );
  }, []);

  useEffect(() => {
    const { emailAddress } = Office.context.mailbox.userProfile;
    let isShared = false;

    if (item?.getSharedPropertiesAsync) {
      item.getSharedPropertiesAsync((properties) => {
        const { owner } = properties.value;
        isShared = Boolean(owner && owner !== emailAddress);
        setIsSharedInbox(isShared);
        setSharedInboxOwner(isShared ? owner : '');
      });
    }

    // NOTE(parlato): These are candidates for shared inbox owners
    // in case the above method fails to detect shared inbox.
    const { participants } = getItemParticipants();
    setSharedInboxCandidates(participants);
  }, [item]);

  const selectSharedInboxCandidate = (email?: string) => {
    setSharedInboxOwner(email ?? '');
    setIsSharedInbox(Boolean(email));
  };

  const storeJobType = (jobType: JobType, jobId?: string) => {
    if (!jobId) return;

    setJobTypes((prev) => ({
      ...prev,
      [jobId]: jobType,
    }));
  };

  const jobs = useMemo(() => {
    const jobsData = data as {
      jobs: {
        edges: {
          node: JobSummaryFragment;
        }[];
      };
    };
    const jobs = jobsData?.jobs.edges.map((edge) => edge.node);
    if (!jobs) return [];

    return filterJobsToDisplay(jobs);
  }, [data]);

  useEffect(() => {
    const jobEdges = data?.jobs.edges;
    if (jobEdges) {
      // NOTE(parlato): This is incorrect - we're currently polling frequently whenever
      // a job exists; this should rather be based on the job's quote and order statuses
      const shouldPollFrequently = jobEdges.length > 0;
      if (shouldPollFrequently) {
        startPolling(POLL_INTERVAL_FREQUENT_SECONDS);
      } else {
        startPolling(POLL_INTERVAL_INFREQUENT_SECONDS);
      }
    }
    return () => {
      stopPolling();
    };
  }, [data, startPolling, stopPolling]);

  return {
    jobs,
    jobsLoading: loading,
    item,
    itemBody,
    conversationId,
    isSharedInbox,
    sharedInboxCandidates,
    jobTypes,
    sharedInboxOwner,
    refetchThreadContext,
    selectSharedInboxCandidate,
    storeJobType,
  };
};
