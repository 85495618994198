import { useOutlookAdaptor } from '@/contexts/useOutlookAdaptor';
import { getRestfulId } from '@/contexts/utils/getRestfulId';
import {
  useCreateOutlookReplyAllMutation,
  useSendOutlookMessageMutation,
} from '@/generated/graphql';
import { useToast } from '@shared/components/toast';
import { useAnalytics } from '@shared/hooks/useAnalytics';

type UseReplyAllState = {
  loadingDraftReplyAll: boolean;
};

type UseReplyAllActions = {
  draftReplyAll: (itemId: string, content?: string) => Promise<void>;
};

export const useReplyAll = (): UseReplyAllState & UseReplyAllActions => {
  const { item, sharedInboxOwner } = useOutlookAdaptor();
  const { sendToast } = useToast();
  const [draftReplyAllMutation, { loading: loadingDraftReplyAll }] =
    useCreateOutlookReplyAllMutation();
  const [sendMessageMutation] = useSendOutlookMessageMutation();
  const { track } = useAnalytics();

  const sendMessage = async (messageId: string) => {
    const res = await sendMessageMutation({
      variables: {
        input: { messageId, sharedInbox: sharedInboxOwner },
      },
    });

    if (!res.data?.sendOutlookMessage?.success) {
      track('Failed to Send Reply All');
      sendToast('Failed to send message', {
        description: 'Something went wrong sending your message.',
        variant: 'error',
      });
    } else {
      track('Sent Reply All');
      sendToast('Success!', {
        description: 'Your message was sent.',
        variant: 'success',
      });
    }
  };

  const draftReplyAll = async (itemId: string, content?: string) => {
    const restfulItemId = item ? getRestfulId(item) : '';
    const isItemInFocus = itemId === restfulItemId;
    const messageId = Office.context.mailbox.convertToRestId(
      itemId,
      Office.MailboxEnums.RestVersion.v2_0
    );
    const res = await draftReplyAllMutation({
      variables: {
        input: {
          messageId,
          content,
          sharedInbox: sharedInboxOwner,
        },
      },
    });

    const draftMessageId = res?.data?.createOutlookReplyAll?.messageId;
    if (!res.data?.createOutlookReplyAll?.success) {
      sendToast('Failed to compose message', {
        description: 'Something went wrong drafting your message.',
        variant: 'error',
      });
    } else if (draftMessageId && isItemInFocus) {
      sendToast('Success!', {
        description: 'Your message was drafted.',
        variant: 'success',
        autoHideDuration: 7 * 1000,
        actions: [
          {
            label: 'Send message',
            onPress: () => {
              sendMessage(draftMessageId);
            },
          },
        ],
      });
    } else if (draftMessageId) {
      Office.context.mailbox.displayMessageForm(draftMessageId);
    }
  };

  return { loadingDraftReplyAll, draftReplyAll };
};
